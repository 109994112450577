import React from 'react'
import PropTypes from 'prop-types'

import { Box, Collapse,
         Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
  collapse: {
    overflowX: "auto",
  },
  collapseBox: {
    width: 0,
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: "0px 8px",
    }
  },
  subtable: {
    overflowX: "auto",
  },
  cellExpand: {
    padding: 0,
    width: 400,
  },
  cellSubtable: {
    padding: "0px 8px",
    textAlign: "left",
    whiteSpace: "nowrap",
  },
});


class ParticipantDressageScoreCellExpand extends React.Component {
  getScore = (score) => {
    if (score && typeof(score) === 'number') {
      return score.toFixed(3)
    }
  }

  getJudgeIdByIndex = (index, type) => {
    let id = 0
    this.props.judges[type].forEach(j => {
      if (j.alias.includes(String(index))) {
        id = j.id
      }
      return ''
    })
    return id
  }

  shouldExpand = () => {
    let participant = this.props.participant
    return (participant.scores.scores_p1 || participant.scores.scores_p2) && this.props.scoresDefinition.length > 0;
  }

  render() {
    const { classes } = this.props
    if (!this.shouldExpand()) {
      return null
    }

    return (
      <TableRow>
        <TableCell className={classes.cellExpand} colSpan={8}>
          <Collapse className={classes.collapse} in={this.props.expanded} timeout="auto" unmountOnExit>
            <Box className={classes.collapseBox}>
              <Table className={classes.subtable} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="right"></TableCell>
                    <TableCell className={classes.cellSubtable}>Día 1</TableCell>
                    { this.props.daysDifferent && <TableCell align="right"></TableCell> }
                    <TableCell className={classes.cellSubtable}>Día 2</TableCell>
                    { this.props.hasScore3 && this.props.daysDifferent &&
                      <TableCell align="right"></TableCell>
                    }
                    { this.props.hasScore3 &&
                      <TableCell className={classes.cellSubtable}>Día 3</TableCell>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.scoresDefinition[0].map((scoreDefinition1, idx) => {
                    let scoreDefinition2 = this.props.scoresDefinition[1][idx] || [""];
                    let scoreDefinition3 = [""];
                    if (this.props.hasScore3 && this.props.daysDifferent) {
                      scoreDefinition3 = this.props.scoresDefinition[2][idx] || [""];
                    }
                    let score_p1 = this.props.participant.scores.scores_p1[idx];
                    score_p1 = score_p1 ? score_p1.toFixed(scoreDefinition1[1]) : "-";
                    let score_p2 = this.props.participant.scores.scores_p2[idx];
                    score_p2 = score_p2 ? score_p2.toFixed(scoreDefinition2[1]) : "-";
                    let score_p3 = this.props.participant.scores.scores_p3[idx];
                    score_p3 = score_p3 ? score_p3.toFixed(scoreDefinition2[1]) : "-";
                    return (
                      <TableRow key={idx}>
                        <TableCell className={classes.cellSubtable} align="right">{scoreDefinition1[0]}</TableCell>
                        <TableCell className={classes.cellSubtable}>{ score_p1 }</TableCell>
                        { this.props.daysDifferent && <TableCell className={classes.cellSubtable} align="right">{scoreDefinition2[0]}</TableCell> }
                        <TableCell className={classes.cellSubtable}>{ score_p2 }</TableCell>
                        { this.props.hasScore3 && this.props.daysDifferent &&
                          <TableCell className={classes.cellSubtable} align="right">{scoreDefinition3[0]}</TableCell>
                        }
                        { this.props.hasScore3 &&
                          <TableCell className={classes.cellSubtable}>{ score_p3 }</TableCell>
                        }
                      </TableRow>
                    )
                  })}

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  }
}

ParticipantDressageScoreCellExpand.propTypes = {
  classes: PropTypes.object.isRequired,
}

ParticipantDressageScoreCellExpand.defaultProps = {
  isMobile: false,
  hasScore3: false,
}

export default withStyles(styles)(ParticipantDressageScoreCellExpand)
