import React from 'react'
import PropTypes from 'prop-types'

import { Link } from "react-router-dom"
import { Button,
         TableCell, TableRow,
         Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Category from 'utils/Category.js'
import DialogMultimedia from 'components/layout/DialogMultimedia.js'
import ParticipantCockade from 'components/pages/participant/ParticipantCockade.js'
import multimedia from 'assets/contest/multimedia.png'
import { getPosition, getScores } from 'utils/utils.js'


// IMPORTANT: ParticipantRow work like JudgeRow too

const styles = theme => ({
  box: {
    borderBottom: "none",
  },
  tableRowParticipant: {
    backgroundColor: "#f1f1f1",
    borderBottom: "1px solid white",
    height: 48,
  },
  tableRowParticipantMobile: {
    backgroundColor: "#f1f1f1",
    //borderBottom: "1px solid white",
  },
  cell: {
    padding: "4px 8px",
  },
  cellMobile: {
    padding: "2px 8px",
  },
  cellPE: {
    padding: 4,
  },
  cellPos: {
    backgroundColor: "black",
    color: "white",
    padding: 0,
    width: 32,
    fontWeight: "bold",
  },
  cellPosMobile: {
    backgroundColor: "black",
    color: "white",
    padding: 0,
    fontSize: 16,
    fontWeight: "bold",
    height: 32,
  },
  link: {
    color: "black",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  livestock: {
    color: "#00000075",
  },
  bold: {
    fontWeight: "bold",
  },
  score: {
  },
  scoreTotal: {
    fontWeight: "bold",
    fontSize: 16,
  },
});


class ParticipantRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  handleDialog = (e) => {
    e.stopPropagation()
    this.setState({open: !this.state.open})
  }

  handleClick = (e) => {
    e.stopPropagation()
  }

  handleClose = (e) => {
    this.setState({open: false})
  }

  render() {
    const { classes, key, participant } = this.props
    let score_types = null;
    if (['S13', 'S14'].includes(participant.section)) {
      score_types = ['total']
    }

    return (
      <React.Fragment>
        {this.props.isMobile ? (
          <React.Fragment>
            <TableRow>
              <TableCell colSpan={3} className={classes.cellPosMobile} align="center">
                { getPosition(participant.pos, participant.scores, participant.type) }
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowParticipantMobile}>
              <TableCell colSpan={2} className={classes.cellMobile} align="left">
                <Category cat={participant.contest.cat} />
                <Link to={`/contests/${participant.type}/${participant.contest.code}/?section=${participant.section_id}`}>
                  <Typography className={classes.link}>
                    { participant.contest.name }
                  </Typography>
                </Link>
              </TableCell>
              <TableCell className={classes.cellMobile} align="left">
                <Typography>{ participant.section }</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowParticipantMobile}>
              <TableCell colSpan={3} className={classes.cellMobile} align="left">
                <Typography>{ participant.name }</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowParticipantMobile}>
              <TableCell colSpan={3} className={`${classes.cellMobile} ${classes.livestock}`} align="left">
                <Typography>{ participant.livestock }</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowParticipantMobile}>
              <TableCell className={classes.cellMobile} align="left">
                { Object.entries(getScores(participant.scores, participant.type, score_types)).map(([name, value], key) => {
                  let cn = classes.score
                  let res = `${name}: ${value}`
                  if (key === 0) { // first value is total
                    cn = classes.scoreTotal
                    res = value
                  }
                  return (
                    <Typography key={key} className={cn} variant="body2" noWrap>{res}</Typography>
                  )
                })}
              </TableCell>
              <TableCell className={classes.cellMobile} align="center">
                {(participant.medias.img1 || participant.medias.video1 || participant.medias.video2) &&
                  <Button className={classes.multimedia} color="primary" onClick={this.handleDialog}>
                    <img src={multimedia} alt="multimedia" />
                  </Button>
                }

                <Typography className={classes.bold} variant="body2">{ participant.extra.mm && "M.M." }</Typography> {/* TODO */}
                <Typography className={classes.bold} variant="body2">{ participant.extra.mf && "M.F." }</Typography> {/* TODO */}
              </TableCell>
              <TableCell className={classes.cellPE} align="left">
                <ParticipantCockade champion={participant.extra.champion}
                                    runner_up={participant.extra.runner_up}
                                    contest_code={participant.contest.code} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
              </TableCell>
            </TableRow>
          </React.Fragment>
        ) : (
          <TableRow key={key} className={classes.tableRowParticipant}>
            <TableCell className={classes.cellPos} align="center">
              { getPosition(participant.pos, participant.scores, participant.type) }
            </TableCell>
            <TableCell className={classes.cell} align="left">
              <Category cat={participant.contest.cat} />
              <Link to={`/contests/${participant.type}/${participant.contest.code}/?section=${participant.section_id}`}>
                <Typography className={classes.link}>
                  { participant.contest.name }
                </Typography>
              </Link>
            </TableCell>
            <TableCell className={classes.cell} align="left">
              <Typography>{ participant.section }</Typography>
            </TableCell>
            <TableCell className={classes.cell} align="left">
              <Typography>{ participant.name }</Typography>
              <Typography className={classes.livestock}>{ participant.livestock }</Typography>
            </TableCell>
            <TableCell className={classes.cell} align="left">
              { Object.entries(getScores(participant.scores, participant.type, score_types)).map(([name, value], key) => {
                let cn = classes.score
                let res = `${name}: ${value}`
                if (key === 0) { // first value is total
                  cn = classes.scoreTotal
                  res = value
                }
                return (
                  <Typography key={key} className={cn} variant="body2" noWrap>{res}</Typography>
                )
              })}
            </TableCell>
            <TableCell className={classes.cell} align="center">
              {(participant.medias.img1 || participant.medias.video1 || participant.medias.video2) &&
                <Button className={classes.multimedia} color="primary" onClick={this.handleDialog}>
                  <img src={multimedia} alt="multimedia" />
                </Button>
              }
              <Typography className={classes.bold} variant="body2">{ participant.extra.mm && "M.M." }</Typography> {/* TODO */}
              <Typography className={classes.bold} variant="body2">{ participant.extra.mf && "M.F." }</Typography> {/* TODO */}
            </TableCell>
            <TableCell className={classes.cellPE} align="left">
                <ParticipantCockade champion={participant.extra.champion}
                                    runner_up={participant.extra.runner_up}
                                    contest_code={participant.contest.code} />
            </TableCell>
          </TableRow>
        )}
        <DialogMultimedia key={participant.number}
                          title={participant.contest.name}
                          img1={participant.medias.img1}
                          video1={participant.medias.video1}
                          video1_desc={ participant.type === 'doma' && 'P1' }
                          video2={participant.medias.video2}
                          video2_desc={ participant.type === 'doma' && 'P2' }
                          video3={participant.medias.video3}
                          video3_desc={ participant.type === 'doma' && 'P3' }
                          open={this.state.open}
                          onClick={this.handleClick}
                          onClose={this.handleClose} />
      </React.Fragment>
    );
  }
}

ParticipantRow.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ParticipantRow)
