import React from 'react'
import PropTypes from 'prop-types'

import { Link } from "react-router-dom"
import { Box,
         Divider, Grid,
         Link as LinkMUI,
         Typography } from '@material-ui/core'
import { withTheme, withStyles } from '@material-ui/core/styles'

import Category from 'utils/Category.js'
import API from 'services/Api.js'
import default_logo_morfphology from 'assets/contest/default_morphology.png'
import default_logo_dressage from 'assets/contest/default_dressage.png'
import Loader from 'components/ui/Loader'


const styles = theme => ({
  waiting: {
    marginLeft: "35%",
  },
  gridContest: {
    maxWidth: 368,
    padding: 16,
    [theme.breakpoints.down('md')]: {
      padding: 8,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 4,
      margin: "auto",
      marginBottom: theme.spacing(2),
    },
  },
  cardng: {
    width: 256,
    margin: '0px 32px',
    [theme.breakpoints.down('md')]: {
      margin: "0px 16px",
    },
    backgroundColor: "#ffffff",
  },
  boxImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#000000",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "auto",
    margin: "auto",
    padding: 0,
  },
  boxInfo: {
    textAlign: "left",
  },
  boxTitle: {
    //textTransform: "capitalize"
  },
  divider: {
    height: 2,
    width: 32,
    background: theme.palette.secondary.main,
    margin: "16px 0px",
    [theme.breakpoints.down('xs')]: {
      margin: "8px 0px",
    },
  },
  bold: {
    fontWeight: "bold",
  },
  link: {
    color: "black",
  },
});


class ContestInfo extends React.PureComponent {
  constructor(props) {
    super(props)
    this._is_mounted = false
    this.first_time = true
    this.state = {
      searching: false,
      sponsors: [],
    }
  }

  componentDidMount() {
    this._is_mounted = true
  }

  componentDidUpdate(prevProps, prevState) {
    // Check year change for search sponsor
    if (prevProps.contest.start !== this.props.contest.start && this.props.contest.start) {
      this.loadSponsor(this.props.contest.start)
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
  }

  extractYear = (date) => {
    if (typeof date == "string") {
      return date.split('/').pop()
    } else {
      return ""
    }
  }

  loadSponsor(start) {
    let year = this.extractYear(start)
    API.sponsor.getList(year, this.props.discipline)
      .then(resp => {
        if (this._is_mounted) {
          this.setState({sponsors: resp})
        }
      })
      .catch(error => {
        // TODO: el searching este iría en el sponsor, si no carga es su culpa, pero el contest si que deberia de cargarse
        this.setState({searching: false})
        console.log("Error loading sponsors: " + year);
      });
  }

  render() {
    const { classes } = this.props

    if (this.state.searching && this.first_time) {
      return <Loader class={classes.waiting} />
    }
    this.first_time = false

    let contest_city = this.props.contest.town + ' / ' + this.props.contest.city + ' / ' + this.props.contest.country

    let default_contest = default_logo_morfphology
    if (this.props.discipline === 'doma') {
      default_contest = default_logo_dressage
    }
    let contest_logo = this.props.contest.logo ? process.env.REACT_APP_MEDIA_URL + this.props.contest.logo : default_contest

    return (
      <Grid className={classes.gridContest} item>
        <Box className={classes.cardng}>
          <Box className={classes.boxImage} mb={1}>
            <img className={classes.img} src={contest_logo} alt="cartel" />
          </Box>
          <Box className={classes.boxInfo} px={1}>
              <Category cat={this.props.contest.cat} />
              <Box className={classes.boxTitle}>
                <Typography variant="h5">{this.props.contest.name}</Typography>
              </Box>

              <Divider className={classes.divider} />

              {!this.props.contest.town && <Loader class={classes.waiting} />}
              {this.props.contest.town && <>
                <Typography className={classes.bold} variant="body2" noWrap={true}>{contest_city}</Typography>
                <Typography variant="body2">{`${this.props.contest.start} - ${this.props.contest.end}`}</Typography>
              </>}

              {this.props.jmorf.length > 0 &&
                <React.Fragment>
                  <Divider className={classes.divider} />
                  <Typography className={classes.bold} variant="body2" noWrap={true}>Jueces Morfología</Typography>
                </React.Fragment>
              }

              {this.props.jmorf.length > 0 && this.props.jmorf.map((judge, key) => {
                let name = judge.name
                if (this.props.section && judge.alias) {
                  let alias = (judge.alias && judge.alias !== 'None') ? judge.alias : '?'
                  name = `${alias} - ${judge.name}`
                }
                return (
                  <Link key={key} className={classes.link} to={{
                    pathname: '/judge',
                    search: `?judge=${judge.id}&contestcode=${this.props.contest.code}`
                  }}>
                    <Typography key={key} variant="body2">{name}</Typography>
                  </Link>
                )
              })}

              {this.props.jfunc.length > 0 && (
                <React.Fragment>
                  <Divider className={classes.divider} />
                  <Typography className={classes.bold} variant="body2" noWrap={true}>Jueces Funcionalidad</Typography>
                </React.Fragment>
                )
              }

              {this.props.jfunc.length > 0 && this.props.jfunc.map((judge, key) => {
                let name = judge.name
                let alias = (judge.alias && judge.alias !== 'None') ? judge.alias : '?'
                if (alias.startsWith('J') && alias.length === 2) {
                  alias = alias.replace('J', 'JF')
                }
                if (this.props.section && judge.alias) {
                  name = `${alias} - ${judge.name}`
                }
                return (
                  <Link key={key} className={classes.link} to={{
                    pathname: '/judge',
                    search: `?judge=${judge.id}&contestcode=${this.props.contest.code}`
                  }}>
                    <Typography key={key} variant="body2">{name}</Typography>
                  </Link>
                )
              })}

              {this.props.judges.length > 0 &&
                <React.Fragment>
                  <Divider className={classes.divider} />
                  <Typography className={classes.bold} variant="body2" noWrap={true}>Jueces</Typography>
                </React.Fragment>
              }

              {this.props.judges.length > 0 && this.props.judges.map((judge, key) => {
                return (
                  <Typography key={key} variant="body2">{judge.full_name}. CAT: {judge.category}</Typography>
                )
              })}

              {this.props.judges_p1 && this.props.judges_p1.length > 0 &&
                <React.Fragment>
                  <Divider className={classes.divider} />
                  <Typography className={classes.bold} variant="body2" noWrap={true}>Jueces P1</Typography>
                </React.Fragment>
              }

              {this.props.judges_p1 && this.props.judges_p1.length > 0 && this.props.judges_p1.map((judge, key) => {
                return (
                  <Typography key={key} variant="body2">{judge.alias}: {judge.full_name}. CAT: {judge.category}</Typography>
                )
              })}

              {this.props.judges_p2 && this.props.judges_p2.length > 0 &&
                <React.Fragment>
                  <Divider className={classes.divider} />
                  <Typography className={classes.bold} variant="body2" noWrap={true}>Jueces P2</Typography>
                </React.Fragment>
              }

              {this.props.judges_p2 && this.props.judges_p2.length > 0 && this.props.judges_p2.map((judge, key) => {
                return (
                  <Typography key={key} variant="body2">{judge.alias}: {judge.full_name}. CAT: {judge.category}</Typography>
                )
              })}

              {this.props.judges_p3 && this.props.judges_p3.length > 0 &&
                <React.Fragment>
                  <Divider className={classes.divider} />
                  <Typography className={classes.bold} variant="body2" noWrap={true}>Jueces P3</Typography>
                </React.Fragment>
              }

              {this.props.judges_p3 && this.props.judges_p3.length > 0 && this.props.judges_p3.map((judge, key) => {
                return (
                  <Typography key={key} variant="body2">{judge.alias}: {judge.full_name}. CAT: {judge.category}</Typography>
                )
              })}

              {/* SPONSOR */}
              {this.state.sponsors.length > 0 && this.state.sponsors.map((sponsor, i) => {
                return (
                  <Box key={i}>
                    <Divider className={classes.divider} />
                    <LinkMUI href={sponsor.link} target="_blank">
                      <img width={sponsor.width} src={sponsor.mobile_img} alt={sponsor.name} />
                    </LinkMUI>
                  </Box>
                )
              })}

          </Box>
        </Box>
      </Grid>
    );
  }
}

ContestInfo.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme(withStyles(styles)(ContestInfo))
