import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

import "plyr-react/plyr.css";
import Video from "components/pages/contest/Video.js";

const styles = (theme) => ({
  gridItem: {
    backgroundColor: "black",
    color: "white",
  },
  boxImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#000000",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "auto",
    margin: "auto",
    padding: 0,
  },
  cell: {
    color: "white",
    fontWeight: "bold",
    textShadow: "1px 1px black",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  items: {
    margin: theme.spacing(1),
    width: 420,
    [theme.breakpoints.up('md')]: {
      width: 448,
    },
    [theme.breakpoints.down("xs")]: {
      margin: "8px 0px",
      width: "100%",
    },
  },
  title: {
    margin: "32px auto 8px 64px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      margin: "32px auto 8px 32px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "32px auto 8px 8px",
    },
  },
  subtitle: {
    marginTop: 16,
    marginBottom: 8,
  },
});

class GalleryVideo extends React.Component {
  getVideos = (elements, discipline) => {
    let res = [];
    elements.forEach((e) => {
      let medias = Object.entries(e.medias).filter(
        ([key, value]) => key.startsWith("video") && value
      );
      medias.forEach((m) => {
        let type = "";
        if (discipline === "doma" && m[0] === "video1") {
          type = "P1";
        } else if (discipline === "doma" && m[0] === "video2") {
          type = "P2";
        } else if (discipline === "doma" && m[0] === "video3") {
          type = "P3";
        } else if (discipline === "morf" && m[0] === "video1") {
          type = "MORF";
        } else if (discipline === "morf" && m[0] === "video2") {
          type = "FUNC";
        } else if (discipline === "morf" && m[0] === "video3") {
          type = "SEMI";
        }
        res.push({
          contest: e.contest,
          pos: e.pos,
          year: e.year,
          media: m[1],
          type: type,
        });
      });
    });
    return res;
  };

  loadVideo = (video) => {
    if (video.includes("youtu.be")) {
      return {
        type: "video",
        sources: [
          {
            src: video,
            provider: "youtube",
          },
        ],
      };
    } else if (video.includes("vimeo")) {
      return {
        type: "video",
        sources: [
          {
            src: video,
            provider: "vimeo",
          },
        ],
      };
    } else {
      return {
        type: "video",
        sources: [
          {
            src: video,
            type: "video/mp4",
          },
        ],
      };
    }
  };

  render() {
    const { classes } = this.props;

    const videosMorf = this.getVideos(
      this.props.participant.contests.morf,
      "morf"
    );
    const videosDoma = this.getVideos(
      this.props.participant.contests.doma,
      "doma"
    );

    return (
      <React.Fragment>
        {videosMorf.length > 0 && (
          <Typography className={classes.title} variant="h4">
            Morfología
          </Typography>
        )}
        <Box className={classes.container}>
          {videosMorf.map((video, key) => {
            return (
              <Box key={key} className={classes.items}>
                <Typography
                  className={classes.subtitle}
                  color="secondary"
                  variant="h6"
                >
                  {video.contest.name} - {video.year} ({video.type})
                </Typography>
                <Video source={video.media} />
              </Box>
            );
          })}
        </Box>

        {videosDoma.length > 0 && (
          <Typography className={classes.title} variant="h4">
            Doma
          </Typography>
        )}
        <Box className={classes.container}>
          {videosDoma.map((video, key) => {
            return (
              <Box key={key} className={classes.items}>
                <Typography
                  className={classes.subtitle}
                  color="secondary"
                  variant="h6"
                >
                  {video.contest.name} - {video.year} ({video.type})
                </Typography>
                <Video source={video.media} />
              </Box>
            );
          })}
        </Box>
      </React.Fragment>
    );
  }
}

GalleryVideo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GalleryVideo);
